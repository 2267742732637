<template>
    <div class="map-container" ref='chartdiv'></div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import * as am4charts from '@amcharts/amcharts4/charts'
import * as am4maps from '@amcharts/amcharts4/maps'
import MapTheamAnimation from '@amcharts/amcharts4/themes/animated'
import Am4themesDark from '@amcharts/amcharts4/themes/dark'
import Am4geodataUsaTerritoriesHigh from '@amcharts/amcharts4-geodata/usaTerritoriesHigh'

am4core.useTheme(MapTheamAnimation)

export default {
  name: 'Heat-Map',
  data () {
    return {
      pickrGeoData: null
    }
  },
  mounted () {
    this.$http
      .get('picklrs/geodata')
      .then(
        function (response) {
          let myDataSet = response.body.data

          var chart = am4core.create(this.$refs.chartdiv, am4maps.MapChart)
          chart.geodata = Am4geodataUsaTerritoriesHigh
          chart.projection = new am4maps.projections.AlbersUsa()
          var polygonSeries = chart.series.push(new am4maps.MapPolygonSeries())
          polygonSeries.useGeodata = true

          // Set min/max fill color for each area
          polygonSeries.heatRules.push({
            property: 'fill',
            target: polygonSeries.mapPolygons.template,
            min: am4core.color('#D2A2BC'),
            max: am4core.color('#782B54')
          })

          // Set up heat legend
          let heatLegend = chart.createChild(am4maps.HeatLegend)
          heatLegend.series = polygonSeries
          heatLegend.align = 'right'
          heatLegend.valign = 'bottom'
          heatLegend.width = am4core.percent(20)
          heatLegend.marginRight = am4core.percent(4)
          heatLegend.minValue = 0
          heatLegend.maxValue = 10000

          var polygonTemplate = polygonSeries.mapPolygons.template
          polygonTemplate.tooltipText = '{name}: {value}'
          polygonTemplate.nonScalingStroke = true
          polygonTemplate.strokeWidth = 0.5

          // Create hover state and set alternative fill color
          var hs = polygonTemplate.states.create('hover')
          hs.properties.fill = am4core.color('#12db6d')

          // Set up custom heat map legend labels using axis ranges
          var minRange = heatLegend.valueAxis.axisRanges.create()
          minRange.value = heatLegend.minValue
          minRange.label.text = 'Min'
          var maxRange = heatLegend.valueAxis.axisRanges.create()
          maxRange.value = heatLegend.maxValue
          maxRange.label.text = 'Max'

          // Blank out internal heat legend value axis labels
          heatLegend.valueAxis.renderer.labels.template.adapter.add('text', function (labelText) {
            return ''
          })

          let imageSeries = chart.series.push(new am4maps.MapImageSeries())
          let imageSeriesTemplate = imageSeries.mapImages.template
          let circle = imageSeriesTemplate.createChild(am4core.Circle)
          circle.radius = 5
          circle.fill = am4core.color('#FFFFFF')
          circle.stroke = am4core.color('#12db6d')
          circle.strokeWidth = 2
          circle.nonScaling = true
          circle.tooltipText = '{title}'
          imageSeriesTemplate.propertyFields.latitude = 'latitude'
          imageSeriesTemplate.propertyFields.longitude = 'longitude'
          polygonSeries.data = myDataSet.stateBreakdown
          imageSeries.data = myDataSet.userZipCodes

          // Create a zoom control
          var zoomControl = new am4maps.ZoomControl()
          chart.zoomControl = zoomControl
          zoomControl.slider.height = 100

          // Add button to zoom out
          var home = chart.chartContainer.createChild(am4core.Button)
          home.label.text = 'Home'
          home.align = 'right'
          home.events.on('hit', function (ev) {
            chart.goHome()
          })
          this.chart = chart
        }
      )
  },
  beforeDestroy () {
    if (this.chart) {
      this.chart.dispose()
    }
  }

}
</script>

<style scoped>
    .map-container {
        width: 100%;
        height: 500px;
    }
</style>
